import React from "react";
import { Layout } from "../components";

export const Profile = () => {
  return (
    <Layout>
      <div>Profile</div>
    </Layout>
  );
};
