import React from "react";
import { BookCategoryTable, Layout } from "../components";

export const Menus = () => {
  return (
    <Layout>
      <div>Menus</div>
      <BookCategoryTable />
    </Layout>
  );
};
