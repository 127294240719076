import React from "react";
import "./App.css";
import { Dashboard, Page404, Menus, Profile } from "./containers";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { PAGE_PATHS } from "./utils/constants";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path={PAGE_PATHS.root}
            element={<Navigate to={PAGE_PATHS.dashboard} />}
          />
          <Route path={PAGE_PATHS.dashboard} element={<Dashboard />} />
          <Route path={PAGE_PATHS.menus} element={<Menus />} />
          <Route path={PAGE_PATHS.profile} element={<Profile />} />
          <Route path={PAGE_PATHS.notFound} element={<Page404 />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
