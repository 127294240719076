import React from "react";
import { Layout } from "../components";

export const Dashboard = () => {
  return (
    <Layout>
      <div>Dashboard</div>
    </Layout>
  );
};
